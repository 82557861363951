define([
    '../util/getCookie',
    '../util/setCookie',
], function ieCheck (getCookie, setCookie) {
    var ua = window.navigator.userAgent,
        ieCookie = getCookie('ieb');

    if ((ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1) && !ieCookie) {
        $('#iecheckTrigger').trigger('click');

        // set cookie if IE
        setCookie('ieb', 'ieb');
    }
});

