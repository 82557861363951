function isTouchDevice() {
    return !hasMousePointer()
        && ('ontouchstart' in window
            || (navigator.maxTouchPoints > 0)
            || (navigator.msMaxTouchPoints > 0)
            || (window.DocumentTouch && document instanceof DocumentTouch)
            || window.matchMedia('(pointer: coarse)').matches);
}

function hasMousePointer() {
    return window.matchMedia('(pointer:fine)').matches;
}
export { isTouchDevice };
