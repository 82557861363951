import { initStream } from './modules/bambuser.js';
import { createFile } from './modules/ics.js';
import $ from 'jquery';

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/**
 * example bambuser frontend module integration
 */
document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.js_bambuser-stream-trigger:not(.js_processed)').forEach(function (el) {
        const { streamId, config } = el.dataset;
        if (streamId) {
            window.console.debug('Intializing Bambuser stream ' + streamId);
            initStream(streamId, el, JSON.parse(config));
            el.classList.add('js_processed');
        } else {
            window.console.debug('No streamId passed');
        }
    });
});

/*
$('.js-create-ics').each(function () {
    let content = createFile($(this).get(0));
   $(this).popover({
        html: true,
        trigger: 'focus',
        content: '<b>Zum Kalender hinzuf�gen</b><br/> <a class="pr-1" href>iCalendar</a>&middot;<a class="p-1" href>Google</a>&middot;<a class="pl-1" href>Outlook</a>',
   }).on('shown.bs.popover', function () {
        var popover = $(this);
        popover.addClass('isOpen');
        let contentEll = popover.data('bs.popover').tip;
        const timestamp = $(this).data('date').split('T')[0].replace(/-/g, '');
        const name = 'BIPA-LIVE-' + $(this).data('summary').toLowerCase().replace(/\s/g, '-') + '_' + timestamp + '.ics';
        let str = contentEll.innerHTML.replace(/href/g, 'href="' + content + '" download="' + name + '"');
        str = str.replace(/popover-body/g, 'popover-body text-center');
        popover.data('bs.popover').tip.innerHTML = str;
   }).on('hidden.bs.popover', function () {
        $(this).removeClass('isOpen');
   });
});
*/
