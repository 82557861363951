document.addEventListener('DOMContentLoaded', function () {
    var youtube = document.querySelectorAll('.youtubevideo');

    for (var i = 0; i < youtube.length; i++) {
	    var source = 'https://img.youtube.com/vi/' + youtube[i].dataset.embed + '/hqdefault.jpg';

	    var image = new Image();
	    image.setAttribute('data-src', source);
	    image.addEventListener('load', (function (j) {
	        youtube[i].appendChild(image);
		    image.className = 'lazyload';
	    }(i)));

	    youtube[i].addEventListener('click', function () {
	        var iframe = document.createElement('iframe');

	            iframe.setAttribute('frameborder', '0');
	            iframe.setAttribute('allowfullscreen', '');
	            iframe.setAttribute('allow', 'autoplay');
	            iframe.setAttribute('src', 'https://www.youtube-nocookie.com/embed/' + $(this).data('embed') + '?autoplay=1&loop=1&autopause=1&muted=1');

	            this.innerHTML = '';
	            this.appendChild(iframe);
	    });
    }
});
