define([
    'jquery'
], function ($) {
    var $notificationBar = $('#notification-bar'),
        promise,
        classActive = 'active',
        whichTransitionEvent = function () {
            var t,
                el = document.createElement('fakeelement'),
                transitions = {
                    transition: 'transitionend',
                    OTransition: 'oTransitionEnd',
                    MozTransition: 'transitionend',
                    WebkitTransition: 'webkitTransitionEnd'
                };

            for (t in transitions) { // eslint-disable-line no-restricted-syntax
                if (Object.prototype.hasOwnProperty.call(transitions, t) && el.style[t] !== undefined) {
                    return transitions[t];
                }
            }
        },
        transitionEvent = whichTransitionEvent(); // do we support transitione events? Which one should we use?

    return function (msg, delay) {
        var notify = function () {
            var deferred = new $.Deferred(),
                resolveIt = function () {
                    deferred.resolve();
                    promise = null;
                };
            promise = deferred.promise();
            $notificationBar.html(msg).addClass(classActive);
            setTimeout(function () {
                $notificationBar.removeClass(classActive);
                if (transitionEvent) { // We suppose that the element always has a CSS transition
                    $notificationBar.one(transitionEvent, resolveIt); // resolve after animation ends
                } else {
                    resolveIt(); // transitionend event not supported, resolve now
                }
            }, delay || 2000); // if a delay is given then use it, default is 2s
        };
        if (msg) {
            if (promise) {
                // another notification is currently shown, wait for it
                promise.then(notify);
            } else {
                notify();
            }
        }
    };
});
