define([
    'jquery',
    'bs-breakpoints',
    'bootstrap'
], function ($, bsBreakpoints) {
    return function () {
        bsBreakpoints.init();

        // link to products
        $('span.to-products').on('click touch', function (event) {
            event.stopPropagation();
            event.preventDefault();
            window.location.href = $(this).parent('a').data('href');
        });

        // click on submenu parent
        $(document).on('click touch', '.dropdown-menu a.dropdown-toggle', function (e) {
            if (['xSmall', 'small', 'medium'].indexOf(bsBreakpoints.detectBreakpoint()) === -1) {
                e.stopPropagation();
                e.preventDefault();
                window.location.href = $(this).data('href');
                return;
            }
            // Catch link click
            if (e.target.classList.contains('to-products')) {
                e.stopPropagation();
                e.preventDefault();
                window.location.href = $(this).data('href');
            }
            // create clickable link
            if (!$(this).next().hasClass('show')) {
                $(this).parents('.dropdown-menu').first().find('.show')
                    .removeClass('show');
            }

            $(this).next('.dropdown-menu').toggleClass('show');

            $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function () {
                $('.dropdown-submenu .show').removeClass('show');
            });

            // close submenus
            if ($(this).parent('li.dropdown-submenu')) {
                $(this).parent('li.dropdown-submenu').find('span.to-products').removeClass('d-none');
                $(this).parent('li.dropdown-submenu').find('span.nav-title').addClass('font-weight-bold');

                if (['xSmall', 'small', 'medium'].indexOf(bsBreakpoints.detectBreakpoint()) > -1) {
                    $(this).parent('li.dropdown-submenu').parents('li.dropdown').children('a')
                        .addClass('border-bottom go-back');
                    $(this).parent('li.dropdown-submenu').parents('li.dropdown').children('a')
                        .find('span.back')
                        .removeClass('d-none');
                    $(this).parent('li.dropdown-submenu').parents('li.dropdown').children('a')
                        .find('span.to-products')
                        .addClass('d-none');
                } else {
                    $(this).parent('li.dropdown-submenu').children('a').addClass('go-back');
                    $(this).parent('li.dropdown-submenu').find('span.back').removeClass('d-none');
                }
            }
            if (['xSmall', 'small', 'medium'].indexOf(bsBreakpoints.detectBreakpoint()) > -1) {
                $(this).parent('li.dropdown-submenu').parents('li.dropdown').children('a.go-back')
                    .on('click touch', function (event) {
                        event.stopPropagation();
                        event.preventDefault();
                        var $submenu = $(this).siblings('.dropdown-menu');
                        $submenu.find('li a span.to-products').addClass('d-none');
                        $submenu.find('li a span.nav-title').removeClass('font-weight-bold');
                        $submenu.find('li.dropdown-submenu:not(.d-none) .dropdown-menu.show').removeClass('show');
                        $submenu.find(' ul li.d-none').removeClass('d-none');

                        $(this).children('span.to-products').removeClass('d-none');
                        $(this).removeClass('border-bottom go-back');
                        $(this).children('span.back').addClass('d-none');
                        if ($(this).attr('aria-expanded') === 'false') {
                            $(this).dropdown('toggle');
                        }
                    });
            } else {
                $(this).on('click touch', function (event) {
                    // Catch link click to products
                    if (event.target.classList.contains('to-products')) {
                        window.location.href = event.target.parentElement.dataset.href;
                    }
                    event.stopPropagation();
                    event.preventDefault();
                    var $submenu = $(this).parent('li');
                    $submenu.find(' a span.to-products').addClass('d-none');
                    $submenu.find(' a span.back').addClass('d-none');
                    $submenu.find(' a span.nav-title').removeClass('font-weight-bold');
                    $submenu.children('.dropdown-menu').removeClass('show');
                    $submenu.siblings('li.d-none').removeClass('d-none');
                    $(this).off(event);
                });
            }

            // Hide sibling categories
            $(this).parent().siblings().addClass('d-none');

            return false;
        });

        // top nav items
        if (['xSmall', 'small', 'medium'].indexOf(bsBreakpoints.detectBreakpoint()) > -1) {
            $('#navbarNav ul.navbar-nav li.nav-item  a.dropdown-toggle').on('click touch', function () {
                // show and hide top categories
                $(this).parent().siblings().addClass('d-none');
                // show and hide subcategories
                $(this).dropdown('toggle');
                $(this).dropdown('toggle');
                // Show to products link
                $(this).find('span.to-products').removeClass('d-none');
                $(this).find('span.nav-title').addClass('font-weight-bold');
                // show back to start
                $('#cattoggle span.back').removeClass('d-none');

                $('#cattoggle').addClass('border-bottom go-back');

                $('#cattoggle.go-back span').on('click touch', function (event) {
                    event.stopPropagation();
                    event.preventDefault();
                    // hide links
                    $('ul.navbar-nav  span.to-products').addClass('d-none');
                    $('ul.navbar-nav  span.back').addClass('d-none');
                    $('ul.navbar-nav  span.nav-title.font-weight-bold').removeClass('font-weight-bold');
                    $('ul.navbar-nav  a.border-bottom').removeClass('border-bottom go-back');
                    // close show dropdowns
                    $('ul.navbar-nav  li.nav-item.show >  a.dropdown-toggle').dropdown('toggle');
                    // show hidden siblings
                    $('ul.navbar-nav li.nav-item ').not('.jsignore').removeClass('d-none');
                    // show hidden submenus
                    $('ul.navbar-nav li.dropdown-submenu ').removeClass('d-none');
                    // remove go back
                    $('#cattoggle span.back').addClass('d-none');
                    $('#cattoggle').removeClass('border-bottom go-back');
                });
            });
        } else {
            // Click on top navigation
            $('ul.navbar-nav li.nav-item > a.dropdown-toggle').on('click touch', function () {
                window.location.href = $(this).data('href');
            });
        }
    };
});
