import '../../../../../../app_bipa/cartridge/static/default/lib/jquery/js/jquery-hoverintent/jquery.hoverintent.min';

const $ = require('jquery');
/**
 * bambuser live shopping frontend module
 */
function initHover() {
    $('#minicart-inner').hoverIntent({
        interval: 150, // milliseconds delay before onMouseOver
        over: function () {
            $('#minicart-overlay').show();
        },
        timeout: 1000, // milliseconds delay before onMouseOut
        out: function () {
            $('#minicart-overlay').hide();
        },
    });
}
/**
 * updates bambuser player with SFRA product data
 * @param {Object} productResponse product data retrieved from sfra
 * @param {Object} player bambuser player instance
 * @param {Object} config module config object
 * @returns {void}
*/
function updateBambuserProduct(productResponse, player, config) {
    if (productResponse) {
        player.updateProduct(productResponse.bambuserPID, function (factory) {
            return factory
                .currency(config.currency)
                .locale(config.locale)
                .product(function (p) {
                    return p
                        .brandName(productResponse.brand)
                        .defaultVariationIndex(0)
                        .description(productResponse.description)
                        .name(productResponse.name)
                        .sku(productResponse.id)
                        .variations(function (v) {
                            return productResponse.variations.map(function (variation) {
                                return v()
                                    .attributes(function (a) {
                                        return a
                                            .colorName(variation.colorName);
                                    })
                                    .imageUrls(variation.imageURLs)
                                    .name(variation.name)
                                    .sku(variation.sku)
                                    .sizes(function (s) {
                                        return variation.sizes.map(function (size) {
                                            return s()
                                                .name(size.name)
                                                .inStock(size.inStock)
                                                .price(function (pr) {
                                                    return pr
                                                        .currency(size.price.currency)
                                                        .current(size.price.current);
                                                })
                                                .sku(size.sku);
                                        });
                                    });
                            });
                        });
                });
        });
    }
}
// -------------------------------------------------------------------------

/**
 * initialize bambuser frontend integration
 */
function initIntegration(config) {
    const basketPLIs = {};

    if (!config) {
        window.console.error('Could not load bambuser player config');
        return;
    }

    if (!config.enabled) {
        window.console.debug('Bambuser integration disabled');
        return;
    }
    // configure player and define handlers
    window.onBambuserLiveShoppingReady = function (player) {
        // basic config
        player.configure({
            locale: config.locale,
            currency: config.currency,
        });

        // handle add to cart
        player.on(player.EVENT.ADD_TO_CART, function (addedItem, callback) {
            const payLoad = new FormData();
            payLoad.append('pid', addedItem.sku);
            payLoad.append('quantity', addedItem.quantity || 1);
            let cart = document.getElementById('minicart');
            window.fetch(
                config.urls.addToCart,
                {
                    method: 'POST',
                    body: payLoad,
                }
            ).then(function (response) {
                return response.text();
            }).then(function (text) {
                let [content, mesh, errorMessage] = text.split(Constants.ajax.contentSplitter);
                if (errorMessage) {
                    callback({
                        success: false,
                        reason: 'internal-error',
                        message: errorMessage,
                    });
                } else {
                    cart.innerHTML = content;
                    callback(true);
                    initHover();
                }
            }).catch(function (e) {
                callback(false);
            });
        });

        // handle checkout (redirect)
        player.on(player.EVENT.CHECKOUT, function () {
            window.location = config.urls.checkout;
        });
        const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
        // handle PROVIDE_PRODUCT_DATA
        player.on(player.EVENT.PROVIDE_PRODUCT_DATA, async function (event) {

            Array.prototype.forEach.call(event.products, function (prod, index) {
                setTimeout(function () {
                    // construct query url
                    const url = new URL(window.location.href);
                    url.pathname = config.urls.productData;
                    url.searchParams.append('id', prod.id || '');
                    url.searchParams.append('ref', prod.ref || '');

                    // fetch and process product data
                    window.fetch(url, { method: 'GET' })
                        .then(function (response) { return response.json(); })
                        .then(function (responseObj) { return updateBambuserProduct(responseObj.product, player, config); });
                }, index * 200);
            });
        });

        // Optional: handle CLOSE
        player.on(player.EVENT.CLOSE, function (event) { // eslint-disable-line no-unused-vars
            // do something
        });

        // handle SYNC_CART_STATE
        player.on(player.EVENT.SYNC_CART_STATE, function (event) { // eslint-disable-line no-unused-vars
            // Currently, update cart does not support anything more than emptying the cart.
            // The Bambuser team is working on providing a way to fully control the in-player cart.
            // For a consistent UX, this event should sync the player minicart with the shop cart
            // on launch
        });

        // handle UPDATE_ITEM_IN_CART
        // Note: due to the possibility of the player cart being out of sync with the shop, some unexpected behavior
        // is possible (see SYNC_CART_STATE handler)
        player.on(player.EVENT.UPDATE_ITEM_IN_CART, function (updatedItem, callback) {
            let cart = document.getElementById('minicart');

            const url = new URL(
                updatedItem.quantity > 0 ? config.urls.updateQuantity : config.urls.removeProduct,
                window.location.origin
            );
            url.searchParams.append('pli', updatedItem.sku);
            url.searchParams.append('quantity', updatedItem.quantity);

            window
                .fetch(
                    url, { method: 'GET' }
                )
                .then(function (response) { return response.text(); })
                .then(function (text) {
                    let [content, mesh, errorMessage] = text.split(Constants.ajax.contentSplitter);
                    if (errorMessage) {
                        callback({
                            success: false,
                            reason: 'internal-error',
                            message: errorMessage,
                        });
                    } else {
                        cart.innerHTML = content;
                        initHover();
                        callback(true);
                    }
                });
        });
    };

    // load bambuser script
    window.initBambuserLiveShopping = function (item) { window.initBambuserLiveShopping.queue.push(item); };
    window.initBambuserLiveShopping.queue = [];
    const scriptNode = document.createElement('script');
    scriptNode.src = config.urls.embedScript;
    document.body.appendChild(scriptNode);
}

/**
 * Initialize LiveShopping Stream
 *
 * @param {string} showId id of stream
 * @param {Element} node trigger DOM element
 * @param {string} type type of player, default overlay
 */
function initStream(showId, node, config, type = 'overlay') {
    // check if integration has been initialized
    if (!window.initBambuserLiveShopping) {
        initIntegration(config);
    }
    // intitialize stream for element
    if (window.initBambuserLiveShopping) {
        window.initBambuserLiveShopping({
            showId: showId,
            node: node,
            type: type,
        });
    }
}

export { initStream };
