define([
    'jquery'
], function ($) {
    return function () {
        var $newsletterModal = $('#newsletter'),
            $newsletterButton = $('.newsletter__button'),
            $newsletterCloseButton = $('.footer-cookie-newsletter .close'),
            $newsletterForm = $newsletterModal.find('form'),
            newsletterOpenedCookie = getCookie('newsletterOpened');

        if (newsletterOpenedCookie && newsletterOpenedCookie === 'true') {
            $newsletterButton.hide();
        }

        $newsletterForm.on('submit', function (e) {
            var newsletterEmailInputValue = document.querySelector('#newsletter form input').value;
            e.preventDefault();

            if (newsletterEmailInputValue) {
                window.location = $newsletterForm.attr('action') + '?newsletterSubscriptionEmail='
                + newsletterEmailInputValue;
            }
        });

        $newsletterButton.on('click', function () {
            if ($newsletterModal.hasClass('show')) {
                $newsletterModal.modal('hide');
            } else {
                $newsletterModal.appendTo('.bootstrap-iso:last').modal('show');
            }
        });

        $newsletterCloseButton.on('click', function () {
            // set cookie
            setCookie('newsletterOpened', 'true');
            $newsletterButton.hide();
        });
    };

    /**
         * Get a browser cookie.
         *
         * @return the value from the given cookie name
         */
    function getCookie(cName) {
        var i,
            x,
            y,
            ARRcookies = document.cookie.split(';'),
            idx;
        for (i = 0; i < ARRcookies.length; i++) {
            idx = ARRcookies[i].indexOf('=');
            x = ARRcookies[i].substr(0, idx);
            y = ARRcookies[i].substr(idx + 1);
            x = x.replace(/^\s+|\s+$/g, '');
            if (x === cName) {
                return unescape(y);
            }
        }
    }

    /*
    * Set a browser cookie.
    *
    */
    function setCookie(cName, value, exdays, path, samesite) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        document.cookie = cName + '=' + (escape(value) + ((exdays === null) ? '' : '; expires='
                + exdate.toUTCString()) + (path ? ';path=' + path : ''))
                + (samesite ? '; samesite=' + samesite : ' samesite=Lax')
                + '; secure';
    }
});
