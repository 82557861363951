document.addEventListener('DOMContentLoaded', function () {
    var countdownBanner = document.querySelectorAll('.countdownBanner');

    for (var i = 0; i < countdownBanner.length; i++) {
        var countdown = countdownBanner[i].querySelector('.js-countdown-timer');
        var precountdownBox = countdownBanner[i].querySelector('.pre-countdown-box');
        // Set the date we're counting down to
        var countdownBannerTargetDate = countdown.dataset.targetdate;

        // Update the count down every 1 second
        var x = setInterval(function() {
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countdownBannerTargetDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result
            countdown.querySelector('.days .js-digit').textContent = pad(days, 2);
            countdown.querySelector('.hours .js-digit').textContent = pad(hours, 2);
            countdown.querySelector('.minutes .js-digit').textContent = pad(minutes, 2);
            countdown.querySelector('.seconds .js-digit').textContent = pad(seconds, 2);

            // If the count down is finished
            if (distance < 0) {
                clearInterval(x);

                if (precountdownBox) {
                    precountdownBox.classList.remove('pre-countdown-box');
                    precountdownBox.classList.add('past-countdown-box');
                }
            }
        }, 1000);
    }
});

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}
