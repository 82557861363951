import $ from 'jquery';

var wWidth = window.innerWidth;

function getConfig(config) {
    let currentConfig = config.default;
    if (wWidth >= 1440) {
        currentConfig = config.xl;
    } else if ((wWidth < 1440) && (wWidth >= 1200)) {
        currentConfig = config.md;
    } else if ((wWidth < 1200) && (wWidth >= 960)) {
        currentConfig = config.sm;
    }
    return currentConfig;
}
function hide(el) {
    let count = 0;
    const config = getConfig(el.data('gridconfig'));
    el.find(' > div').each(function () {
        count++;
        if (count > config.maxItems) {
            $(this).addClass('d-none');
        }
    });
}

$('.productgrid__wrapper').each(function () {
    hide($(this));
});

document.addEventListener('DOMContentLoaded', function () {
    $('.productgrid__wrapper').each(function () {
        let $this = $(this);
        $.ajax({
            url: $(this).data('ajax') + '&limit=' + $this.data('limit'),
            success: function (data) {
                $this.html(data);
                hide($this);
            },
        });
    });
});
