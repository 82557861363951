define([],
 function promobar () {
    var $header = $('#header'),
        promoWrapperEl = $header.find('.js-header-promotion-wrapper'),
        sessionUpdateUrl = window.Constants.url.promoSessionUpdate,
        sessionPromoAID = promoWrapperEl.attr('data-session-promo-id'),
        currentPromoAID = promoWrapperEl.attr('data-asset-id'),
        hasActivePromo = promoWrapperEl.attr('data-has-active-promo') === 'true',
        requiresNewPromoAID = currentPromoAID !== sessionPromoAID;

    if (requiresNewPromoAID && currentPromoAID !== 'null') {
        promoWrapperEl.removeClass('d-none');
    }

    // Header promo banner close button
    $header.on('click', '.js-header-promo-close', function (e) {
        $('#searchbox').addClass('mt-searchbox');
        $('#minicart-overlay').css('margin-top', 0);
        $('#account-login-popdown').css('margin-top', 0);
        $('#clickncollect--dropdown-menu').css('margin-top', 0);
        $('.main--header__wrapper').removeClass('promo-active');

        e.preventDefault();

        if ((!hasActivePromo && currentPromoAID) || requiresNewPromoAID) {
        // make a call to update that the current promo banner has been clicked
        // and update the session promo asset ID
            $.ajax({
                url: sessionUpdateUrl + '?promoAssetId=' + currentPromoAID
            }).done(function () {
                promoWrapperEl.addClass('d-none');
            });
        }
    });
});