import 'jquery-hoverintent';
/*
* Enhance the behaviour for the mini cart.
*/
let $minicart = $('#minicart'),
    $minicartOverlay = $('#minicart-overlay');

/*
* Initialize the hover effect for the mini cart flyout.
*/
export function initHover() {
    $minicartOverlay = $('#minicart-overlay');

    $('#minicart-inner').hoverIntent({
        interval: 150, // milliseconds delay before onMouseOver
        over: function () {
            show();
        },
        timeout: 1000, // milliseconds delay before onMouseOut
        out: function () {
            hide();
        }
    });

    $(document).on('cartProductAdded', initHover);
    $(document).on('cartProductRemoved', initHover);
}

/*
* Show the mini cart flyout.
*/
function show() {
    $minicart.addClass('active');
    $minicartOverlay.stop(true, true).fadeIn('fast');
}

/*
* Hide the mini cart flyout.
*/
function hide() {
    $minicart.removeClass('active');
    $minicartOverlay.stop(true, true).fadeOut('fast');
}

/**
 * Aminate add product to cart.
 *
 * @param imgtodrag  the product img to drag
 */

function animateProductAdd(imgtodrag, minicartEl) {
    if (imgtodrag.length > 0) {
        var $imgclone = imgtodrag.clone();
        $imgclone.css('position', 'absolute');
        $imgclone.css('top', imgtodrag.offset().top);
        $imgclone.css('left', imgtodrag.offset().left);
        $imgclone.css('z-index', 100);
        $imgclone.css('width', imgtodrag.width());
        $imgclone.css('height', imgtodrag.height());

        $('body').append($imgclone);
        $.when($imgclone.animate({
            top: minicartEl.offset().top,
            left: minicartEl.offset().left,
            width: '25px',
            height: '25px',
            opacity: 1
        }, 500)).done(function () {
            $(this).remove();
        });
    }
}
