define([
    'jquery'
], function ($) {
    /**
     * Helper method to convert a serializedArray to an javascript object hash.
     *
     * @param array the serializedArray
     *
     * @return the object with the parameters of the given array
     */
    return function (array) {
        var obj = {};
        $.each(array, function (index, entity) {
            obj[entity.name] = entity.value;
        });
        return obj;
    };
});
