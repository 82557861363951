import $ from 'jquery';
import 'slick-carousel';
import { isTouchDevice } from './device';

var defaultConfig = {
    prevArrow: '<div class="carousel-prev"><svg class="z" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><defs><style>.a{fill:#e6007e;stroke:#e6007e;}.a,.b{stroke-width:2px;}.b,.d{fill:none;}.b{stroke:#fff;stroke-linecap:round;}.c{stroke:none;}</style></defs><g class="a"><circle class="c" cx="20" cy="20" r="20"/><circle class="d" cx="20" cy="20" r="19"/></g><g transform="translate(20.676 29.321) rotate(225)"><line class="b" x1="0.147" y2="13.457" transform="translate(13.489 0.531)"/><line class="b" x1="13.468" y1="0.001" transform="translate(0.021 0.531)"/></g></svg></div>',
    nextArrow: '<div class="carousel-next"><svg class="z" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><defs><style>.a{fill:#e6007e;stroke:#e6007e;}.a,.b{stroke-width:2px;}.b,.d{fill:none;}.b{stroke:#fff;stroke-linecap:round;}.c{stroke:none;}</style></defs><g class="a"><circle class="c" cx="20" cy="20" r="20"/><circle class="d" cx="20" cy="20" r="19"/></g><g transform="translate(17.676 10.321) rotate(45)"><line class="b" x1="0.147" y2="13.457" transform="translate(13.489 0.531)"/><line class="b" x1="13.468" y1="0.001" transform="translate(0.021 0.531)"/></g></svg></div>',
    rows: 0,
};

function initSlick(el, config) {
    if (el.parent('.js-scroll-box').length && isTouchDevice()) {
        return;
    }
    el.on('init setPosition', function () {
        el.parent('.js-scroll-box').toggleClass('scroll', !el.hasClass('slick-slider'));
        el.addClass('container');
        el.closest('.experience-component.experience-layouts-row').addClass('overflow-hidden');
        el.closest('.experience-component.experience-layouts-row').find('.slick-list').addClass('overflow-visible');
    });

    el.slick(config);
}

$('[data-slick]').each(function () {
    var config = $.extend({}, defaultConfig, $(this).data('slick') || {});
    initSlick($(this), config);
});

$('.carousel--ProductTiles .product-listing-carousel').each(function () {
    let parent = $(this);
    if (!parent.data('ajax') || !parent.data('limit') || !parent.data('ajax-slick')) {
        return true;
    }
    $.ajax({
        url: parent.data('ajax') + '&limit=' + parent.data('limit'),
        success: function (data) {
            var config = $.extend({}, defaultConfig, parent.data('ajax-slick') || {});
            parent.html(data);
            initSlick(parent, config);
        },
    });
});

$(window).on('resize', function () {
    $('.carousel--ProductTiles .product-listing-carousel:not(.slick-initialized)[data-ajax-slick]').each(function () {
        let config = $.extend({}, defaultConfig, $(this).data('ajax-slick') || {});
        initSlick($(this), config);
    });
    $('[data-slick]:not(.slick-initialized)').each(function () {
        let config = $.extend({}, defaultConfig, $(this).data('slick') || {});
        initSlick($(this), config);
    });
    $('[data-einstein-slick]:not(.slick-initialized)').each(function () {
        let config = $.extend({}, defaultConfig, $(this).data('einstein-slick') || {});
        initSlick($(this), config);
    });
});
