define([],function() {
   return function setCookie(cname, cvalue, exdays) {
        var d = new Date(),
            expires = 'expires=';
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        expires += d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    };
});


