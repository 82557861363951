define([
    'jquery',
    '../util/appendparamtourl',
    'jquery-ui/ui/widgets/autocomplete'
], function (jQuery, appendParamToURL) {
    var boldString = function (str, find) {
            find = find.replace(/\s*$/, '');
            var splitted = find.split(' ');
            splitted.forEach(function (substr) {
                if (substr !== 'b' && substr !== 'B') {
                    var reg = new RegExp('(' + substr + ')', 'gi');
                    str = str.replace(reg, '<b>$1</b>');
                }
            });

            return str;
        },
        searchSuggest = function () {
            var cache = {},
                lastXhr,
                searchField = jQuery('#searchAutoComplete'),
                form,
                extension = {},
                sRenderItemData = '_renderItemData',
                sRenderMenu = '_renderMenu',
                // sResizeMenu = '_resizeMenu',
                $jqTarget,
                jqTargetHref,
                tapTarget,
                tapTimestamp,
                theValue;

            if (searchField.length) {
                form = jQuery('#SimpleSearchForm');

                extension[sRenderItemData] = function ($ul, item) {
                    $ul.addClass('bootstrap-iso search__suggestions--wrapper');
                    var res,
                        appendTo,
                        itemLabel = item.label.replace('NO NAME ', ''),
                        itemLink = item.link,
                        itemClass = '',
                        itemValue = item.value.replace('NO NAME ', ''),
                        searchTerm = $('#searchAutoComplete').val();

                    itemLabel = boldString(itemLabel, searchTerm);
                    switch (item.type) {
                    case 'product':
                        appendTo = 'ol.product';
                        itemClass = 'border-bottom pl-0';
                        res = '<a  href="' + itemLink + '" title="' + itemValue + '"><div class="row">'
                            + '<div class="col-3"><img width="' + item.imgW + '" height="' + item.imgW + '" alt="'
                            + itemValue + '" src="' + item.imgPath + '" /></div>'
                            + '<div class="col-9 align-self-center text-wrap text-truncate">' + itemLabel + '</div>'
                        + '</div></a>';
                        break;
                    case 'term':
                        appendTo = 'ol.term';
                        res = '<a><span class="category">' + itemLabel + '</span></a>';
                        break;
                    case 'brand':
                        appendTo = 'ol.brand';
                        res = '<a href="' + itemLink + '" title="' + itemValue + '"><span class="category">'
                            + itemLabel + '</span></a>';
                        break;
                    case 'category':
                        appendTo = 'ol.category';
                        res = '<a href="' + itemLink + '" title="' + itemValue + '"><span class="category">'
                            + itemLabel + '</span> </a>';
                        break;
                    case 'viewall':
                        appendTo = 'ol.suggestion-container';
                        res = '<a class="btn btn-outline-primary text-primary btn-all-products" href="'
                        + itemLink + '" title="' + itemLabel
                        + '">' + itemLabel + '</a>';
                        break;
                    case 'nohit':
                        appendTo = 'ol.suggestion-container';
                        res = '<span class="category js-disabled">' + itemLabel + '</span>';
                        break;
                    default:
                    }
                    if (res) {
                        res = jQuery('<li class="suggestion ' + itemClass + '"></li>')
                            .data('ui-autocomplete-item', item)
                            .append(res)
                            .addClass('ui-menu-item')
                            .appendTo($ul.find(appendTo));
                    }
                    return res;
                };
                // before item rendering, build headline, viewall and custom markup
                extension[sRenderMenu] = function ($ul, items) {
                    var self = this,
                        hasProducts = false,
                        currentCat = '',
                        wWidth = window.innerWidth;

                    $ul.append(jQuery('<li class="row ml-3 mr-5 my-0 border-top">'));
                    $ul.append(jQuery('<li class="row mx-0 ui-menu-item"></li>').data('ui-autocomplete-item', {})
                        .append(wWidth < 960 ? '<div class="d-sm-block col-sm-6 pt-25">'
                                    + '<ol class="category mb-0"></ol>'
                                    + '<ol class="brand mb-0"></ol>'
                                    + '<ol class="product"></ol>'
                                    + '<ol class="term mb-0"></ol>'
                                    + '<ol class="suggestion-container"></ol>'
                                + '</div>'
                            : '<div class="d-none d-sm-block col-sm-6 pt-25">'
                                    + '<ol class="category"></ol>'
                                    + '<ol class="brand"></ol>'
                                    + '<ol class="term"></ol>'
                                + '</div>'
                                + '<div class="col-12 col-sm-6 target"><ol class="product"></ol><ol class="suggestion-container"></ol></div>'));
                    // + '<div class="bottom"><ol class="beautylounge">' +
                    // '</ol></div>'
                    jQuery.each(items, function () {
                        var item = this;
                        if (item.category !== currentCat) {
                            $ul.find('ol.' + item.type)
                                .append(
                                    '<li class="ui-category ui-menu-item text-primary" data-ui-autocomplete-item="{}">'
                                    + item.category + '</li>'
                                );
                            currentCat = item.category;
                        }
                        self[sRenderItemData]($ul, item);

                        if (Object.prototype.hasOwnProperty.call(item, 'count') && item.count > 0 && !hasProducts) {
                            hasProducts = true;
                        }
                    });

                    if (hasProducts) {
                        $ul.addClass('has-products');
                    } else {
                        $ul.removeClass('has-products');
                    }
                };

                jQuery.extend(jQuery.ui.autocomplete.prototype, extension);

                /* if user focuses search field in header */
                jQuery(document).on('focus blur', '#sitesearch.usual', function (e) {
                    var siteSearch = searchField.parents('.sitesearch');
                    if (e.type === 'focusin') {
                        siteSearch.addClass('focused');
                    } else {
                        setTimeout(function () {
                            siteSearch.removeClass('focused');
                        }, 1000);
                    }
                });

                searchField.autocomplete({
                    open: function (event) {
                        var wWidth = window.innerWidth;
                        if (wWidth >= 960) {
                            $(this).autocomplete('widget').css({
                                width: ($('#searchbox').width() + 'px')
                            });
                            $(event.target).autocomplete('widget').hide().fadeIn();
                            $('#searchbox').removeClass('rounded-pill');
                            $('#searchbox').css('border-top-left-radius', '1.5rem');
                            $('#searchbox').css('border-top-right-radius', '1.5rem');
                        } else {
                            var $searchBox = $('#searchbox'),
                                searchMarginTop = $searchBox.css('margin-top'),
                                suggestStyles = 'left: ' + $searchBox.css('margin-left') + ' !important;';

                            suggestStyles += 'width: ' + $searchBox.width() + 'px;';
                            suggestStyles += 'top: ' + (parseInt(searchMarginTop.slice(0, searchMarginTop.length - 2), 10)
                        + parseInt($searchBox.height(), 10)) + 'px;';
                            suggestStyles += 'position: fixed';

                            $('body').addClass('position-relative');
                            $('body').addClass('noscroll');
                            $('#ui-id-1').attr('style', suggestStyles);

                            $(event.target).autocomplete('widget').hide().fadeIn();
                            $('#searchbox').removeClass('rounded-pill');
                            $('#searchbox').css('border-top-left-radius', '1.5rem');
                            $('#searchbox').css('border-top-right-radius', '1.5rem');
                        }
                    },
                    close: function (event) {
                        $('body').removeClass('position-relative');
                        $('body').removeClass('noscroll');
                        $(event.target).autocomplete('widget').show().fadeOut('fast');
                        $('#searchbox').addClass('rounded-pill');
                    },
                    source: function (request, response) {
                        var resultArray = [],
                            term = request.term;
                        if (Object.prototype.hasOwnProperty.call(cache, term)) {
                            response(cache[term]);
                            return;
                        }
                        /* jslint unparam: true */
                        lastXhr = jQuery.ajax({
                            url: searchField.data('searchsuggesturl'),
                            data: { q: term },
                            error: jQuery.noop,
                            success: function (res, status, xhr) {
                                var i = 0,
                                    splitted = res.split('%%%%SEPARATOR%%%%'),
                                    data = jQuery.parseJSON(splitted[0]);
                                for (i = 0; i < data.length; i++) {
                                    resultArray.push(data[i]);
                                }
                                cache[term] = resultArray;
                                if (xhr === lastXhr) {
                                    response(resultArray);
                                }
                            }
                        });
                    },
                    select: function (event, ui) {
                        if (!ui || !ui.item
                            || !jQuery(event.originalEvent.originalEvent.target).is('.ui-menu-item li *')
                            || $(event.originalEvent.originalEvent.target).hasClass('js-disabled')) {
                            return false;
                        }
                        var item = ui.item;
                        event.preventDefault();
                        if (!item.link) {
                            if (!item.value) {
                                $jqTarget = jQuery(event.originalEvent.originalEvent.target).closest('a');
                                if ($jqTarget.length) {
                                    jqTargetHref = $jqTarget.attr('href');
                                    if (jqTargetHref) {
                                        window.location.href = jqTargetHref;
                                    } else {
                                        theValue = $jqTarget.find('.category').html();
                                    }
                                }
                            } else {
                                theValue = item.value;
                            }
                            searchField.val(theValue);
                            form.submit();
                        } else {
                            window.location.href = item.link;
                        }
                        return false;
                    }
                });

                // on submit we do not submit the form, but change the window location
                // in order to avoid https to http warnings in the browser
                // only if it's not the default value and it's not empty
                form.on('submit', function () {
                    var searchTerm = searchField.val();
                    if (searchTerm !== '' && searchField.length) {
                        window.location.href = appendParamToURL(form.attr('action'), 'q', searchTerm);
                    }
                    return false;
                });

                /* BIPA-2346: links in the suggest flyout have to be tapped twice to be
               followed (this is iOS standard for links with :hover CSS class).
               Strangely the beautylounge links can be accessed with one tap.
               They both use the same styles, so the difference is hard to distinguish. But
               all links are supposed to be accessed with only one click. */
                jQuery(document).on('touchstart', '.ui-autocomplete li.ui-menu-item a', function (event) {
                    tapTarget = event.target;
                    tapTimestamp = event.timeStamp;
                }).on('touchend', '.ui-autocomplete li.ui-menu-item a', function (event) {
                    if (tapTarget === event.target && event.timeStamp - tapTimestamp < 250) {
                        jQuery(this).trigger('click');
                    }

                    tapTarget = null;
                    event.preventDefault();
                });
            }
        };
    return searchSuggest;
});
