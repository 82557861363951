define([
    'jquery',
    'bs-breakpoints',
    'bootstrap-dropdown-hover'
], function ($, bsBreakpoints) {
    return function () {
        var scrollValue = 0,
            scrollTimeout = false;
        bsBreakpoints.init();
        if ($('#header').find('.js-header-promotion-wrapper').length > 0) {
            var calc = 'calc(' + $('#header').find('.js-header-promotion-wrapper').outerHeight(true)
                + 'px + ' + $('#searchbox').css('margin-top') + ')',
                promoHeight = 'calc(' + $('#header').find('.js-header-promotion-wrapper').outerHeight(true)
                + 'px)';
            if ($('#header').find('.js-header-promotion-wrapper').hasClass('js-promo-active')) {
                $('.main--header__wrapper').addClass('promo-active');
            }

            if ($('#searchbox').css('position') === 'fixed') {
                $('#searchbox').removeClass('mt-searchbox');
                $('#searchbox').css('margin-top', calc);
                $('#minicart-overlay').css('margin-top', promoHeight);
                $('#clickncollect--dropdown-menu').css('margin-top', promoHeight);
                $('#account-login-popdown').css('margin-top', promoHeight);
            }
        }

        function loadSubMenu(cat) {
            var menu = $(cat).find('.dropdown-menu')[0];
            if (menu && cat.dataset.id) {
                $.get(window.Constants.url.submenuURL,
                    { cgid: cat.dataset.id }).done(function (data) {
                    $(menu).html(data);
                    if (['xSmall', 'small', 'medium'].indexOf(bsBreakpoints.detectBreakpoint()) === -1) {
                        $('.subnav-container').addClass('container');
                    }
                }).fail(function () {
                    menu.remove();
                });
            }
        }

        $('div#navbarNav ul.navbar-nav .dropdown').hover(function () {
            $(this).addClass('show');
            $(this).find('> .dropdown-menu').stop(true, true).delay(300)
                .fadeIn(200);
        }, function () {
            $(this).removeClass('show');
            $(this).find('> .dropdown-menu').stop(true, true).delay(300)
                .hide(100);
        });

        $('#burger_menu').on('click', function () {
            $('div#navbarNav div#topNav ul.navbar-nav li.nav-item.dropdown').each(function (index, topCat) {
                loadSubMenu(this);
                $(this).off('click');
            });
        });

        $('div#navbarNav div#topNav ul.navbar-nav li.nav-item.dropdown').on('mouseover', function () {
            loadSubMenu(this);
            $(this).off('mouseover');
        });

        if (['xSmall', 'small', 'medium'].indexOf(bsBreakpoints.detectBreakpoint()) === -1) {
            $('div#navbarNav ul.navbar-nav [data-hover="dropdown"]').bootstrapDropdownHover({
                clickBehavior: 'default',
                hideTimeout: 200
            });

            $('#accountMenuDropdown').bootstrapDropdownHover({
                clickBehavior: 'default',
                hideTimeout: 200
            });
        } else {
            $('#navbarNav ul.navbar-nav').removeClass('container flex-nowrap');
        }

        $('#navbarNav .isdirectlink').on('click touch', function () {
            window.location.href = $(this).attr('href');
        });

        /** Searchbar visibility */
        if (window.innerWidth >= 960 && $('#searchbox').hasClass('d-none')) {
            $('#searchbox').removeClass('d-none');
        }
        $('.searchbox-toggle').on('click touch', function () {
            $('#searchbox').toggleClass('d-none');
            if ($('#searchbox').hasClass('d-none')) {
                $('#searchAutoComplete').blur();
                $('.inactive-overlay').hide();
            } else {
                $('#searchAutoComplete').focus();
                $('.inactive-overlay').show();
            }
        });

        /** Storelocator */
        $(document).on('shown.bs.modal', '#stores', function () {
            var $storelocatorIframe = $('#cnclocator iframe'),
                iframeSource = $storelocatorIframe.attr('data-source');

            $storelocatorIframe.prop('src', iframeSource);
        }).on('show.bs.modal', '#stores', function () {
            $('#stores').appendTo('body');
        })
            .on('hide.bs.modal', function () {
                $(document.getElementsByTagName('HTML')[0]).css({ overflow: 'initial' });
                $(document.body).css({ overflow: 'initial', position: 'initial' });
            });
        $(window).on('scroll', function () {
            /* Clear it so the function only triggers when scroll events have stopped firing */
            clearTimeout(scrollTimeout);
            /* Set it so it fires after a second, but gets cleared after a new triggered event */
            scrollTimeout = setTimeout(function () {
                var scrolled = $(document).scrollTop() - scrollValue;
                scrollValue = $(document).scrollTop();
                if (Math.abs(scrolled) > 300 && window.innerWidth < 960 && $('#searchAutoComplete').val() === '') {
                    $('#searchbox').addClass('d-none');
                    $('#searchAutoComplete').blur();
                    $('.inactive-overlay').hide();
                }
            }, 100);
        })
            .on('resize', function () {
                if (window.innerWidth > 960 && $('#searchbox').hasClass('d-none')) {
                    $('#searchbox').removeClass('d-none');
                }
            });

        $('ul.navbar-nav .navbar-toggler').on('click touch', function () {
            $(this).find('#burger_menu').toggleClass('open');
            if ($(this).hasClass('collapsed')) {
                $(document.body).css({ overflow: 'hidden' });
                $('.navbar-container').css({ overflow: 'scroll', height: '90%', position: 'fixed' });
            } else {
                $(document.body).css({ overflow: 'scroll' });
                $('.navbar-container').css({ overflow: '', height: '', position: '' });
            }
        });

        $('#minicart-inner .minicart-btn.mini-cart').on('click touch', function () {
            window.location.href = $(this).attr('href');
        });
    };
});
