define([
], function () {
    /**
     * Appends a parameter with the given name and
     * value to the given url and returns the changed url
     *
     * @param url the URL to which the parameter should be appended
     * @param name the name of the parameter
     * @param value the parameter's value
     *
     * @return the URL with appended parameter
     */
    return function (url, name, value) {
        var c = '?';
        if (url.indexOf(c) !== -1) {
            c = '&';
        }
        return url + c + name + '=' + encodeURIComponent(value);
    };
});
