document.addEventListener('DOMContentLoaded', function () {
    var acceptCookies = window.dataLayer.filter(function (data) {
            return data.event == 'OptanonLoaded'
            && data.OptanonActiveGroups === ',C0001,';
        }).length === 0,
        localExpires = localStorage.getItem('OneTrustExpires');

    if (localExpires === null && acceptCookies === false && document.cookie.indexOf('OptanonAlertBoxClosed') > -1) {
        var now = date = new Date();
        const expireDays = 1;
        date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        const cValue = now.toUTCString(),
            expires = date.toUTCString(),
            cName = 'OptanonAlertBoxClosed';

        document.cookie = cName + '=' + cValue + '; expires=' + expires + '; path=/'
        + (location.host.startsWith('www') ? '; domain=' + location.host : '');
        if (window.localStorage) {
            localStorage.setItem('OneTrustExpires', expires);
        }
    } else if (acceptCookies && localExpires !== null) {
        localStorage.removeItem('OneTrustExpires');
    }
});
