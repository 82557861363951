/*
 * Swipe event plugin for jQuery.
 * Provide swipe handling (events "swipe", "swipeLeft", "swipeRight") for elements.
 *
 * When touch events aren't supported mouse events will be used.
 *
 * This code is based upon the jQuery UI swipe handling.
 */
define([
    'jquery'
], function ($) {
    var supportTouch = document.ontouchend !== undefined,
        touchMoveEvent = supportTouch ? 'touchmove' : 'mousemove',

        scrollSupressionThreshold = 10, // More than this horizontal displacement, and we will suppress scrolling.
        durationThreshold = 1000, // More time than this, and it isn't a swipe.
        horizontalDistanceThreshold = 30, // Swipe horizontal displacement must be more than this.
        verticalDistanceThreshold = 75, // Swipe vertical displacement must be less than this.

        $eventSpecial = $.event.special,
        abs = Math.abs,
        leftRightEvent = {
            setup: function () {
                $(this).on('swipe', $.noop);
            }
        },

        getOriginalEventTouches = function (e) {
            return e.originalEvent.touches;
        };

    $eventSpecial.swipe = { // register the "swipe" event

        setup: function () {
            var $this = $(this);
            $this.on(supportTouch ? 'touchstart' : 'mousedown', function (event) {
                var originalEventTouches = getOriginalEventTouches(event),
                    data = originalEventTouches ? originalEventTouches[0] : event,
                    startTime = (new Date()).getTime(),
                    startCoords = [data.pageX, data.pageY],
                    $startOrigin = $(event.target),
                    stopTime,
                    stopCoords,

                    moveHandler = function (evnt) {
                        if (!startTime) {
                            return;
                        }
                        var vOriginalEventTouches = getOriginalEventTouches(evnt),
                            vData = vOriginalEventTouches ? vOriginalEventTouches[0] : evnt;

                        stopTime = (new Date()).getTime();
                        stopCoords = [vData.pageX, vData.pageY];

                        // prevent scrolling
                        if (abs(startCoords[0] - stopCoords[0]) > scrollSupressionThreshold) {
                            evnt.preventDefault();
                        }
                    };

                $this.on(touchMoveEvent, moveHandler)
                    .one(supportTouch ? 'touchend' : 'mouseup', function () {
                        $this.off(touchMoveEvent, moveHandler);

                        if (startTime && stopTime) {
                            if (stopTime - startTime < durationThreshold
                                    && abs(startCoords[0] - stopCoords[0]) > horizontalDistanceThreshold
                                    && abs(startCoords[1] - stopCoords[1]) < verticalDistanceThreshold) {
                                $startOrigin.trigger('swipe').trigger(startCoords[0] > stopCoords[0] ? 'swipeleft'
                                    : 'swiperight');
                            }
                        }
                        startTime = undefined;
                        stopTime = undefined;
                    });
            });
        }
    };
    $eventSpecial.swipeleft = leftRightEvent; // register the "swipeLeft" event
    $eventSpecial.swiperight = leftRightEvent; // register the "swipeRight" event
});
