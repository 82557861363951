define([
    'jquery'
], function ($) {
    $('#flyer').on('shown.bs.modal', function () {
        var $flyoutContent = $('#flyoutContent');
        $flyoutContent.height($('#flyer .modal-content').height());
        $flyoutContent.width($('#flyer .modal-content').width() - 50);
        $flyoutContent.attr('src', $flyoutContent.data('src'));
    });
});
