define([
    './searchsuggest',
    './menu',
    './stacknavigation',
    '../mobile/common/newsletter',
    './promobar',
    './oneTrust',
    './flyer',
    './ieCheck',
], function (searchsuggest, menu, navigation, newsletter) {

    document.addEventListener('lazyloaded', function () {
        var $loaded = $('.card-img-top.lazyloaded');
        $loaded.closest('.image--placeholder').addClass('loaded');
        $loaded.closest('.image--loading').removeClass('image--loading');
    });

    return function () {
        searchsuggest();
        menu();
        navigation();
        newsletter();
    };
});
