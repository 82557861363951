/* global Constants: false */
define([
    'jquery',
    '../../util/convertarraytoobject',
    '../util/notification',
    '../../util/touchevents'
], function ($, convertArrayToObject, notification) {
    var $document = $(document),
        $minicart = $('#minicart'),
        contentSplitter = '%%%%SPLITHERE%%%%',
        addProductURL,

        /*
         * Adds a product to the basket.
         * When the given element (jqEl) has the class "animateadd" then there's a animation defined for this
         * element.
         * In this case the function triggers "evMiniCartAdd". The event handler of this component gets a
         * callback function as extra parameter.
         * This event handler should start the animation and after the first part of the animation the callback
         * should be called with two
         * parameters:
         * - callback function, called when the request was successful and the content is loaded.
         * - callback function, called when the "add to cart" overlay is closing.
         */
        addProductInternal = function (data, $el, notify, $elToShow) {
            var theData = data || {},
                adding = false,
                ajaxAdd = function (afterAddToCart, onClose) {
                    $.post(addProductURL, theData, function (res) {
                        var splitted = res.split(Constants.ajax.contentSplitter),
                            Native = window.Native;
                        $minicart.html(splitted[0]);

                        updateAddedQuantityText($el, splitted[2]);

                        addToCartOverlay($el);

                        /* callback to bipa app native interface */
                        if (Native && Native.cart) {
                            Native.cart($minicart.find('.minicart--menuElement').data('content'));
                        }
                        if (afterAddToCart) {
                            afterAddToCart(splitted[2]);
                        }
                        if (splitted[3] === 'true' ) {
                            $('#button-cart').popover({
                                container: 'body',
                                content: splitted[7],
                                html: true,
                                trigger: 'focus',
                                placement: 'top',

                              }).popover('show');
                              adding = false;
                        }

                        if (splitted[4] === 'true') {
                            notification(splitted[5]);
                            adding = false;
                        }

                        // check for availability error when adding product and passing an fake
                        // add to cart overlay element to show th error in the overlay
                        if (splitted[6] === 'true' && $elToShow) {
                            $elToShow.find('.errmsg').removeClass('hide-el');
                        }

                        if (notify && splitted[4] !== 'true') {
                             /* notification(cartAddedTemplate); */
                            // addtocartoverlay($elToShow ? $elToShow.html() : splitted[1]);
                            adding = false;
                        }
                        // callback

                        /* to be consumed by Google Tag Manager library if present */
                        $document.trigger({
                            type: 'cartProductAdded',
                            miniCart: $('<html />').html(res)
                        });
                    });
                };
            if (!adding) {
                adding = true;
                if ($el && $el.hasClass('animateadd')) {
                    $el.trigger('minicartadd', ajaxAdd);
                } else {
                    ajaxAdd();
                }
            }
        },
        /**
         * Add a product to cart.
         *
         * @param productID  the product to add
         * @param qty        the quantity to add (default 1)
         */
        addProduct = function (productID, qty) {
            addProductInternal({
                pid: productID,
                Quantity: qty || 1
            });
        },
        addProductList = function (productListID) {
            addProductInternal({
                ProductListID: productListID
            });
        },
        update = function () {
            if ($minicart.length) {
                $.get($minicart.data('updateurl'), function (res) {
                    $minicart.find('.cart-amount').html(res);
                });
            }
        },
        removePli = function (url) {
            if (url) {
                $.get(url).done(function (res) {
                    var splitted = res.split(contentSplitter);

                    if ($minicart) {
                        $minicart.html(splitted[0]);
                        $minicart.addClass('show');
                    }
                    $document.trigger({
                        type: 'cartProductRemoved',
                        miniCart: $('<html />').html(res)
                    });
                }).fail(function () {
                    window.location.reload();
                });
            }
        },

        updateAddedQuantityText = function(addForm, quantity) {
            var productQuantity = $('#quantity', addForm);
            var addToCartOverlayQuantity = $('button.add-overlay .overlay-quantity',addForm);
            let qty = quantity || productQuantity.val();
            if (addToCartOverlayQuantity.length && productQuantity.length) {
                addToCartOverlayQuantity.text(qty);
            }
        },

        addToCartOverlay = function(addForm) {
            $('button.add-overlay',addForm).addClass('d-block');
            setTimeout(function(){
                $('button.add-overlay', addForm).removeClass('d-block');
                $('[data-toggle="popover"]', addForm).popover({
                    container: '.bootstrap-iso'
                  }).popover('show');
                setTimeout(function(){
                    $('[data-toggle="popover"]', addForm).popover('hide');
                }, 5000);
            }, 2000);
        };

    return {
        init: function () {
            if ($minicart.length) {
                addProductURL = $minicart.data('addminiurl');

                $document.on('submit', 'form.addtocartform', function (ev) {
                    var $this = $(this),
                        $productSetProductsForms = $this.parent().find('.pdp__productset--product__wrapper form'),
                        $fakeProductSetAddToCart = null;

                    if (ev.currentTarget.id === 'productset-addtocartform') {
                        $fakeProductSetAddToCart = $this.parent().find('.pdp__set--fakeaddtocart');

                        ev.preventDefault();

                        $productSetProductsForms.each(function (setProductFormIndex) {
                            if (setProductFormIndex === 0) {
                                addProductInternal($($productSetProductsForms[setProductFormIndex]).serialize(),
                                    $($productSetProductsForms[setProductFormIndex]), true, $fakeProductSetAddToCart);
                            } else {
                                addProductInternal($($productSetProductsForms[setProductFormIndex]).serialize());
                            }

                            adding = false;
                        });
                    } else if ($this.find('.isCart').length > 0 || $this.hasClass('notanimateadd')) {
                        addProductInternal(convertArrayToObject($this.serializeArray()), $this, false);
                        if($this.hasClass('notanimateadd')) ev.preventDefault();
                    } else {
                        addProductInternal(convertArrayToObject($this.serializeArray()), $this, true);
                        ev.preventDefault();
                    }
                })
                    .on('click touch', 'form.addtocartform button.addtocart', function (ev) {
                        var $this = $(this);
                        $this.parents('form').submit();
                        $this.blur();
                        ev.preventDefault();
                    })
                    .on('click', 'form.addtocartform button.addsettocart', function (ev) {
                        var $this = $(this),
                            setproducts = $this.parent().serializeArray();
                        ev.preventDefault();
                        $this.blur();
                        $.each(setproducts, function (index, product) {
                            var psp = JSON.parse(product.value);
                            addProductInternal({
                                pid: psp.id,
                                Quantity: psp.quantity
                            }, $this, true);
                        });
                    })
                    // extension for wishlist and bestellabo
                    .on('click', 'form.wishlisttocart .allAddToCart', function (ev) {
                        addProductInternal({
                            ProductListID: $('input#productListID').val()
                        }, $('form.wishlisttocart .allAddToCart'), true);
                        ev.preventDefault();
                    })
                    .on('click', 'form.b2blisttocart .allAddToCart', function (ev) {
                        addProductInternal({
                            ProductListID: $('input#productListID').val()
                        }, $('form.b2blisttocart .allAddToCart'), true);
                        ev.preventDefault();
                    })
                    // eslint-disable-next-line max-len
                    .on('click', 'form.wishlisttocart button.addtocart, form.bestellabotocart button.addtocart, form.b2blisttocart .addtocart', function (ev) {
                        var $targetButton = $(this);
                        addProductInternal({
                            pid: $targetButton.data('productid'),
                            Quantity: $targetButton.data('quantity')
                        }, $targetButton, true);
                        ev.preventDefault();
                    })
                    .on('click', 'form.quantityselectionmulti button.voucher', function (ev) {
                        var $targetButton = $(this);
                        addProductInternal({
                            pid: $targetButton.data('pid'),
                            Quantity: $targetButton.data('quantity')
                        }, $targetButton, false);
                        ev.preventDefault();
                    })
                    .on('click', 'form.quantityselectionmulti button.couponcode', function (ev) {
                        var $this = $(this),
                            url = $this.data('url'),
                            data = [{ name: 'couponCode', value: $this.data('couponcode') }];

                        $.ajax({
                            url: url,
                            data: data,
                            success: function () {
                                notification('Hinzugefügt');
                            }
                        });
                        ev.preventDefault();
                    })
                    .on('click', '#minicart-overlay table td .remove', function (ev) {
                        ev.preventDefault();
                        // .minicarttotal
                        removePli($(this).data('updateurl'));
                    });
            }
        },
        api: {
            addProduct: addProduct,
            addProductList: addProductList,
            update: update,
            removePli: removePli
        }
    };
});
